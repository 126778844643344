import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';
import LocationsMap from './LocationsMap';
import LocationsList from './LocationsList';

const OurLocations = ({ locations }) => {
  return (
    <Section paddingBottom={11} backgroundColor="#E5ECEF">
      <Container>
        <Row>
          <Col>
            <SectionTitle marginBottom={4.5}>Our Locations</SectionTitle>

            <LocationsMap locations={locations} />

            <LocationsList locations={locations} />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

OurLocations.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OurLocations;
