/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import SVG from 'react-inlinesvg';

// Config
import { themeVars } from '../../config/styles';

// Assets
import marker from '../../assets/icons/pin.svg';

const cssWrapper = css`
  position: relative;
`;

const cssPin = css`
  width: 55px;
  height: 55px;
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute; /* Required to maintain position of marker on zoom */
  transform: translate(-50%, -50%); /* Required to maintain position of marker on zoom */
`;

const cssInfoWindow = css`
  position: absolute;
  transform: translate(15%, -50%);
  z-index: 800;
  background-color: #fff;
  padding: 25px;
  width: auto;
  min-width: 250px;
  font-size: 1.4rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);

  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #ffffff;
    display: block;
    width: 0;
    z-index: 1;
    left: -14px;
    top: 80px;
  }
`;

const cssAddress = css`
  list-style: none;
  padding: 0;
  margin: 0 0 15px;

  li {
    margin: 0;

    &:first-of-type {
      font-weight: 600;
      margin-bottom: 15px;
      font-size: 1.6rem;
      text-decoration: underline;
    }
  }
`;

const cssContact = css`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0 0 5px;

    a {
      color: ${themeVars.root.primary};
      text-decoration: underline;
    }
  }
`;

const cssClose = css`
  position: absolute;
  top: 5px;
  right: 0;
  border: transparent;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

const LocationsMarker = ({
  activeIndex,
  handleMarkerOpen,
  handleMarkerClose,
  isInfoWindow,
  index,
  address1,
  address2,
  address3,
  city,
  email,
  name,
  postcode,
  tel,
}) => {
  return (
    <div css={cssWrapper}>
      <button css={cssPin} onClick={() => handleMarkerOpen(index)}>
        <SVG src={marker} />
      </button>

      {isInfoWindow && index === activeIndex && (
        <div css={cssInfoWindow}>
          <ul css={cssAddress}>
            <li>{name}</li>
            <li>{address1}</li>
            <li>{address2 && address2}</li>
            <li>{address3 && address3}</li>
            <li>{city}</li>
            <li>{postcode}</li>
          </ul>

          <ul css={cssContact}>
            <li>
              <a href={`mailto:${email}`}>{email}</a>
            </li>
            <li>
              <a href={`tel:${tel}`}>{tel}</a>
            </li>
          </ul>

          <button css={cssClose} onClick={handleMarkerClose}>
            <Close />
          </button>
        </div>
      )}
    </div>
  );
};

LocationsMarker.defaultProps = {
  address2: null,
  address3: null,
};

LocationsMarker.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  handleMarkerClose: PropTypes.func.isRequired,
  handleMarkerOpen: PropTypes.func.isRequired,
  isInfoWindow: PropTypes.bool.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  address3: PropTypes.string,
  city: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
};

export default LocationsMarker;
