/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import LocationsItem from './LocationsItem';

const cssItems = css`
  .location-item {
    margin-bottom: 40px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const LocationsList = ({ locations }) => {
  return (
    <Row css={cssItems}>
      {locations && locations.map && locations.map((location) => (
        <Col xl={3} lg={6} key={shortid.generate()} className="location-item">
          <LocationsItem
            address1={location.address_1}
            address2={location.address_2}
            address3={location.address_3}
            city={location.city}
            email={location.email}
            name={location.name}
            postcode={location.postcode}
            tel={location.tel}
          />
        </Col>
      ))}
    </Row>
  );
};

LocationsList.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LocationsList;
