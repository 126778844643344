import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core/';
import PropTypes from 'prop-types';

// MUI Overrides
const styles = {
  root: {
    fontSize: 16,
  },
};

const MUITextInput = (props) => {
  const { classes } = props;

  return (
    <TextField
      autoComplete="false"
      fullWidth
      InputProps={{ classes: { input: classes.root } }}
      margin="normal"
      name="name"
      variant="outlined"
      {...props}
    />
  );
};

MUITextInput.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(MUITextInput);
