/**  */

// eslint-disable-next-line
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { css, jsx } from '@emotion/core';

const cssWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = (props) => {
  return (
    <div css={cssWrapper}>
      <CircularProgress color="primary" {...props} />
    </div>
  );
};

export default Loading;
