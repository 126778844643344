/**  */

// eslint-disable-next-line
import React, { useLayoutEffect, useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

// Components
import ContactForm from '../components/Contact/ContactForm';
import LayoutPage from '../components/Layouts/LayoutPage';
import OurLocations from '../components/Contact/OurLocations';
import Section from '../components/Common/Section/Section';

const cssContentWrapper = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const ContactPage = ({ pageContext }) => {
  const { restData } = pageContext;

  return (
    <LayoutPage
      backgroundImage={restData.acf.page_hero_bg}
      heroTitle={restData.acf.page_hero_title}
      imageSlider={restData.acf.page_image_slider}
      imageSliderImages={restData.acf.page_image_slider_images}
      title={restData.title.rendered}
      yoastMeta={restData.yoast_meta}
    >
      <main>
        <Container fluid css={cssContentWrapper}>
          <Section>
            <Container>
              <Row>
                <Col>
                  <ContactForm />
                </Col>
              </Row>
            </Container>
          </Section>

          <OurLocations locations={restData.acf.contact_locations} />
        </Container>
      </main>
    </LayoutPage>
  );
};

export default ContactPage;

ContactPage.defaultProps = {
  pageContext: {
    restData: {
      acf: {
        content_modules: null,
        page_image_slider_images: null,
        page_image_slider: false,
        page_hero_title: '',
      },
    },
  },
};

ContactPage.propTypes = {
  pageContext: PropTypes.shape({
    restData: PropTypes.shape({
      acf: PropTypes.shape({
        content_modules: PropTypes.arrayOf(PropTypes.shape({})),
        page_hero_bg: PropTypes.shape({}).isRequired,
        page_image_slider: PropTypes.bool,
        page_image_slider_images: PropTypes.arrayOf(PropTypes.shape({})),
        page_hero_title: PropTypes.string,
        contact_locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      }).isRequired,
      title: PropTypes.shape({
        rendered: PropTypes.string.isRequired,
      }),
      yoast_meta: PropTypes.shape({}).isRequired,
    }).isRequired,
  }),
};
