import React from 'react';
import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// MUI Overrides
const styles = {
  root: {
    borderRadius: 0,
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '1.25px',
    minWidth: 160,
    minHeight: 50,
    background: "linear-gradient(270deg, #009EE2 0%, #14255B 100%)"
  },
  xlarge: {
    padding: 15,
    minWidth: 240,
    fontSize: 15,
  },
  
};

const MUIButton = (props) => {
  const { classes, xlarge, ...rest } = props;

  return (
    <Button
      className={clsx(classes.root, xlarge && classes.xlarge)}
      color="primary"
      size="large"
      type="submit"
      variant="contained"
      {...rest}
    >
      {props.children}
    </Button>
  );
};

MUIButton.defaultProps = {
  xlarge: false,
};

MUIButton.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    xlarge: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  xlarge: PropTypes.bool,
};

export default withStyles(styles)(MUIButton);
