/**  */

// eslint-disable-next-line
import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const LocationsItem = ({ address1, address2, address3, city, email, name, postcode, tel }) => {
  const cssWrapper = css`
    min-height: 340px;
    overflow: hidden;

    a {
      color: ${themeVars.root.primary};

      &:hover {
        text-decoration: underline;
      }
    }
  `;

  const cssTitle = css`
    font-size: 2rem;
    color: ${themeVars.root.primary};
    margin-bottom: 20px;
  `;

  const cssAddress = css`
    list-style: none;
    margin: 0 0 20px;
    color: #000;
    line-height: 1;
    padding: 0;
  `;

  const cssLabel = css`
    font-weight: 600;
    margin-bottom: 0;
  `;

  const cssValue = css`
    margin-bottom: 1rem;
    display: block;
  `;

  return (
    <Card css={cssWrapper}>
      <CardContent>
        <h3 css={cssTitle}>{name}</h3>

        <ul css={cssAddress}>
          <li>{address1}</li>
          {address2 && <li>{address2}</li>}
          {address3 && <li>{address3}</li>}
          <li>{city}</li>
          <li>{postcode}</li>
        </ul>

        <p css={cssLabel}>Email</p>

        <a href={`mailto:${email}`} css={cssValue}>
          {email}
        </a>

        <p css={cssLabel}>Telephone</p>

        <a css={cssValue} href={`tel:${tel}`}>
          {tel}
        </a>
      </CardContent>
    </Card>
  );
};

LocationsItem.defaultProps = {
  address2: null,
  address3: null,
};

LocationsItem.propTypes = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  address3: PropTypes.string,
  city: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
};

export default LocationsItem;
