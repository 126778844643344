/**  */

// eslint-disable-next-line
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'react-grid-system';
import * as Yup from 'yup';
import { css, jsx } from '@emotion/core';

// Vars
import { themeVars } from '../../config/styles';

// Components
import MUIButton from '../Common/Button/MUIButton';
import MUITextInput from '../Common/Inputs/MUITextInput';
import SectionTitle from '../Common/Section/SectionTitle';
import Loading from '../Common/Loading';

const ContactForm = () => {
  const [submit, setSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const cssSendStatus = css`
    margin: 50px 0;
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
  `;

  const cssBtn = css`
    margin-top: 50px;
  `;

  const cssTextArea = css`
    margin-top: 0 !important;
  `;

  // Enconde the form values for use with Netlify forms
  const encode = (formValues) =>
    Object.keys(formValues)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(formValues[key])}`)
      .join('&');

  // Define the validation schema for the form - https://github.com/jquense/yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    companyName: Yup.string().required(),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter your email address'),
    phone: Yup.number('Enter a valid phone number')
      .required('Please enter your phone number')
      .typeError('Please enter a valid number'),
    message: Yup.string('Enter a valid message').required('Please enter a message'),
  });

  return (
    <>
      {!submit ? (
        <Formik
          initialValues={{ name: '', email: '', companyName: '', phone: '', message: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setIsLoading(true);

            fetch('/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({
                'form-name': 'contact',
                ...values,
              }),
            })
              .then((res) => {
                if (res.status === 200) {
                  setSubmit(true);
                  setSubmitting(false);
                  setIsLoading(false);

                  // Track the submission event
                  window.gtag('event', 'click', {
                    event_category: 'enquiry',
                    event_label: 'contact form',
                  });
                }
              })
              .catch(() => {
                setSubmitting(false);
                setIsLoading(false);
                setIsError(true);
              });
          }}
          render={({ handleSubmit, handleChange, handleBlur, values, errors }) => {
            if (isLoading) {
              return <Loading />;
            }

            if (isError) {
              return <p css={cssSendStatus}>There was a problem. Please try again later.</p>;
            }

            return (
              <form name="contact" onSubmit={handleSubmit} netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <Row>
                  <Col xl={3} lg={12}>
                    <SectionTitle color={themeVars.root.dark} marginBottom={3}>
                      Get in touch
                    </SectionTitle>

                    <p>
                      Fill out the form and an Apex Consulting Engineer representative will follow up as soon as
                      possible.
                    </p>

                    <p>Alternatively you can see our locations and contact information below.</p>
                  </Col>

                  <Col xl={9} lg={12}>
                    <Row>
                      <Col md={12}>
                        <MUITextInput
                          css={cssTextArea}
                          error={typeof errors.message === 'string'}
                          fullWidth
                          label="Message"
                          multiline
                          name="message"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          rows={10}
                          value={values.message}
                        />
                      </Col>

                      <Col md={6}>
                        <MUITextInput
                          error={typeof errors.name === 'string'}
                          label="Name"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                        />
                      </Col>

                      <Col md={6}>
                        <MUITextInput
                          error={typeof errors.email === 'string'}
                          label="Email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                        />
                      </Col>

                      <Col md={6}>
                        <MUITextInput
                          error={typeof errors.companyName === 'string'}
                          label="Company Name"
                          name="companyName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.companyName}
                        />
                      </Col>

                      <Col md={6}>
                        <MUITextInput
                          error={typeof errors.phone === 'string'}
                          label="Phone"
                          name="phone"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone}
                        />
                      </Col>
                    </Row>

                    <div css={cssBtn}>
                      <MUIButton type="submit">Submit</MUIButton>
                    </div>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      ) : (
        <p css={cssSendStatus}>We have received your message and will be in touch shortly</p>
      )}
    </>
  );
};

export default ContactForm;
