/**  */

// eslint-disable-next-line
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Map Styles - https://snazzymaps.com/style/42/apple-maps-esque
import mapStyles from '../../assets/map.json';

import { bp } from '../../config/breakpoints';

// Components
import LocationsMarker from './LocationsMarker';

const cssWrapper = css`
  height: 800px;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: ${bp.mx_md}) {
    height: 500px;
  }
`;

// London, UK
const centerLoc = {
  center: {
    lat: 51.544,
    lng: 0.13317000000006374,
  },
  zoom: 7,
};

const LocationsMap = ({ locations }) => {
  const [isInfoWindow, setInfoWindow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  function handleMarkerOpen(index) {
    setInfoWindow(true);
    setActiveIndex(index);
  }

  function handleMarkerClose() {
    setInfoWindow(false);
  }

  const API_KEY = process.env.GATSBY_GMAPS_KEY;

  return (
    <div css={cssWrapper}>
      <GoogleMapReact
        options={{ panControl: false, mapTypeControl: false, scrollwheel: false }}
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={centerLoc.center}
        defaultZoom={7}
        mapStyles={mapStyles}
        yesIWantToUseGoogleMapApiInternals
        resetBoundsOnResize
        scroll
      >
        {locations && locations.map && locations.map((location, index) => (
          <LocationsMarker
            activeIndex={activeIndex}
            address1={location.address_1}
            address2={location.address_2}
            address3={location.address_3}
            city={location.city}
            email={location.email}
            handleMarkerClose={handleMarkerClose}
            handleMarkerOpen={handleMarkerOpen}
            index={index}
            isInfoWindow={isInfoWindow}
            key={shortid.generate()}
            lat={location.lat}
            lng={location.lng}
            name={location.name}
            postcode={location.postcode}
            tel={location.tel}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

LocationsMap.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LocationsMap;
